<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    LOCAL,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    ADVERTISEMENT_GET_ADVERTISEMENTS,
    ADVERTISEMENT_INITIAL_GET_ADVERTISEMENTS_STATE
  } from '@/core/store/advertisement.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'AdvertisementList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.advertisement'),
            route: { name: ROUTE_NAME.ADVERTISEMENT }
          },
          { title: i18nHelper.getMessage('label.advertisementList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.ADVERTISEMENT,
        actions: {
          create: ACTION.ADVERTISEMENT_CREATE,
          read: ACTION.ADVERTISEMENT_READ,
          update: ACTION.ADVERTISEMENT_UPDATE,
          delete: ACTION.ADVERTISEMENT_DELETE
        }
      },
      filters: [
        {
          label: 'title',
          key: 'title',
          value: ''
        },
        {
          label: 'action',
          key: 'action',
          value: null,
          options: listService.getAdvertisementActionList(),
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'position',
          key: 'position',
          value: null,
          options: listService.getDynamicList(
            CONSTANTS_DATA.advertisementPosition
          ),
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'advertisement',
          state: 'advertisements',
          action: ADVERTISEMENT_GET_ADVERTISEMENTS,
          initialStateAction: ADVERTISEMENT_INITIAL_GET_ADVERTISEMENTS_STATE,
          title: i18nHelper.getMessage('label.getAdvertisements')
        },
        viewDetails: {
          routeName: ROUTE_NAME.ADVERTISEMENT_DETAILS
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'title',
          name: 'title',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_TEXT_TRUNCATE_CONTAINER
        },
        {
          key: 'action',
          name: 'action',
          active: true,
          sortable: true
        },
        {
          key: 'position',
          name: 'position',
          active: true,
          sortable: true
        },
        {
          key: 'createdAt',
          name: 'createdAt',
          active: true,
          sortable: true,
          sortField: 'createdAtTimeStamp'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt',
          active: true,
          sortable: true,
          sortField: 'updatedAtTimeStamp'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newAdvertisement'),
        routeName: ROUTE_NAME.ADVERTISEMENT_NEW
      }
    })
  };
</script>

<style></style>
